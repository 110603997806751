<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Detail de l'utilisateur <strong>#{{user.id}}</strong></h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nom:">
                <b-form-input v-model="user.nom"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Prenom:">
                <b-form-input v-model="user.prenom"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Email:">
                <b-form-input v-model="user.email"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Statut:">
                <select class="form-control" v-model.number="user.status">
                  <option value="1">Actif</option>
                  <option value="1">Inactif</option>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group label="Url:">
            <b-form-input :value="'https://panel.mobixgroup.com/review-poster.html?token='+user._id.$oid" readonly></b-form-input>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      user:{
        _id:{
          $oid:""
        },
        id: 0,
        nom:"",
        prenom:"",
        email:"",
        date_create:null,
        date_update:null,
        status:1
      },
      params: {
        ReviewUser:[]
      },
    };
  },
  components: {
  },
  watch: {
  },
  methods: {
    save(){
      this.$store.back.ajax('/magic/update?module=ReviewUser', this.user, (res) => {
        if(res.status === true){
          this.user = res.data;
          this.$bvToast.toast(`Votre utilisateur est bien sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
        }
      });
    }
  },
  computed: {
  },
  beforeMount(){
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
    this.$store.back.ajax(`/magic/${this.$route.params.id}?module=ReviewUser`, null, (res) => {
      if(res.status === true){
        this.user = res.data.model
      }
    });
  }
};
</script>
